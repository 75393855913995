// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-countdown-js": () => import("./../../../src/pages/countdown.js" /* webpackChunkName: "component---src-pages-countdown-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-h-js": () => import("./../../../src/pages/h.js" /* webpackChunkName: "component---src-pages-h-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-negocios-5-a-4-wdf-9-a-6-d-54-sf-js": () => import("./../../../src/pages/negocios_5a4wdf9a6d54sf.js" /* webpackChunkName: "component---src-pages-negocios-5-a-4-wdf-9-a-6-d-54-sf-js" */),
  "component---src-pages-partners-privacy-policy-js": () => import("./../../../src/pages/partners/privacy-policy.js" /* webpackChunkName: "component---src-pages-partners-privacy-policy-js" */),
  "component---src-pages-partners-terms-of-service-js": () => import("./../../../src/pages/partners/terms-of-service.js" /* webpackChunkName: "component---src-pages-partners-terms-of-service-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-riders-privacy-policy-js": () => import("./../../../src/pages/riders/privacy-policy.js" /* webpackChunkName: "component---src-pages-riders-privacy-policy-js" */),
  "component---src-pages-riders-terms-of-service-js": () => import("./../../../src/pages/riders/terms-of-service.js" /* webpackChunkName: "component---src-pages-riders-terms-of-service-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

